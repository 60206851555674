<div class="login-page">
    <div class="login-left"></div>
    <div class="login-right">
        <div class="l-form">
            <div class="logo">
                <figure>
                    <img src="assets/img/png/logo.png">
                </figure>
            </div>
            <div class="form-start">
                <form [formGroup]="form">
                    <div class="form-fb">
                        <div class="form-grp">
                            <label>New Password</label>
                            <input type="password" formControlName="newPassword" class="form-control" placeholder="Enter New Password" />
                            <app-validator [control]="f['newPassword']" [isSubmitted]="isSubmitted"></app-validator>
                        </div>
                        <div class="form-grp">
                            <label>Confirm Password</label>
                            <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Enter Confirm Password" />
                            <app-validator [control]="f['confirmPassword']" [isSubmitted]="isSubmitted"></app-validator>
                            <small class="text-danger" *ngIf="isSubmitted && form.controls['confirmPassword'].hasError('mustMatch')">Password and confirm password must match.</small>
                        </div>
                        <div class="save-btn">
                            <button class="btn theme_btn" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>