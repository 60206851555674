import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuard } from './core/guards/auth/auth.guard';

const routes: Routes = [

  {
    path: 'auth', 
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pages', 
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  { path: '', pathMatch: "full", redirectTo: '/auth/login' },
  { path: 'reset-password', component: ResetPasswordComponent },
  { 
    path: 'static', 
    loadChildren: () => import('./static-module/static-module.module').then(m => m.StaticModuleModule) 
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
