import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import { ValidatorComponent } from './validator/validator.component';


@NgModule({
  declarations: [
    SidebarComponent,
    ValidatorComponent
  ],
  exports:[
    SidebarComponent,
    MatSliderModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    ValidatorComponent,
    ValidatorComponent
  ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    RouterModule,
    MatSlideToggleModule,
    NgxIntlTelInputModule,
    MatTabsModule,
    MatInputModule
  ],

})
export class SharedModule { }
