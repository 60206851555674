import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CommonService } from '../../services/common/common.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {

  constructor(   private spinner : NgxSpinnerService,
    private commonService: CommonService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();

    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let token = null;
    let guest = null;
    let city = null;

    if(this.commonService.getFromLocalStorage("cure_admin_token")){
      token = this.commonService.getFromLocalStorage("cure_admin_token");
    }else{
      token = null;
    }
    if(localStorage.getItem('cure_city')){
      city = JSON.parse(localStorage.getItem('cure_city'));
    }
    

    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : '',
        city: city ? city: ''
      }),
    });
    if (
      (req.method.toLowerCase() == "post" ||
        req.method.toLowerCase() == "put") &&
      req.body instanceof FormData
    ) {
      authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: token ? `Bearer ${token}` : '',
          city: city ? city : ''
        }),
      });
    }
    return authReq;
  }
}