import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../../../services/common/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private commonService: CommonService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (next.routeConfig.path === 'pages') {
      if(this.commonService.getFromLocalStorage("cure_admin_token")){
        const token = this.commonService.getFromLocalStorage("cure_admin_token");
        if (token !== undefined) {
          return true;
        } else {
          this.router.navigateByUrl('/auth/login');
          return false;
        }
      }else{
        this.router.navigateByUrl('/auth/login');
          return false;
      }
    }  
    if (next.routeConfig.path === 'auth') {
      if(this.commonService.getFromLocalStorage("cure_admin_token")){
       const token = this.commonService.getFromLocalStorage("cure_admin_token");
        if (token !== undefined) {
          this.router.navigateByUrl('/pages/dashboard');
          return false;
        }
      }else {
        return true;
      }
    }
  }
}
