import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-status',
  templateUrl: './confirmation-status.component.html',
  styleUrls: ['./confirmation-status.component.scss']
})
export class ConfirmationStatusComponent implements OnInit {
  status: boolean = false;

  constructor(public dialog: MatDialogRef<ConfirmationStatusComponent>) { }

  ngOnInit(): void {
    console.log(this.status,"status");
  }

  onCancel(){
    this.dialog.close();
  }

  onStatusChange(){
    this.dialog.close('yes');
  }
}
