import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpsService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
    
  constructor(
    private notification: MatSnackBar,
    private zone: NgZone,
  ) {}


  getFromLocalStorage(val) {
    let data = JSON.parse(localStorage.getItem(val));
    return data;
  }

  setLocalStorage(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  }

  successMessage(message: string, action = 'Success', duration = 4000) {
    this.zone.run(() => {
      this.notification.open(message, action, {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      });
      setTimeout(() => {
        this.notification.dismiss();
      }, duration);
    });
  }

  errorMessage(message: string, action = 'Error', duration = 4000) {
    this.zone.run(() => {
      this.notification.open(message, action, {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      });
      setTimeout(() => {
        this.notification.dismiss();
      }, duration);
    });
  }

  validateImageFile(fileData) {
    if (fileData.type == 'image/jpeg' || fileData.type == 'image/png' || fileData.type == 'image/jpg' || fileData.type == 'image/svg' || fileData.type == 'image/webp' || fileData.type == 'image/gif') {
      return true;
    } else {
      this.errorMessage('Only Image file is accepted!')
      return false;
    }
  } 

  validatePdfFile(fileData) {
    if (fileData.type == 'application/pdf') {
      return true;
    } else {
      this.errorMessage('Only Pdf file is accepted!')
      return false;
    }
  } 
 
}
