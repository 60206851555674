import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { BehaviorSubject,Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment'
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationSocketService {
  
  socket: any;
  token:any;
  notificationObserve: any;
  messageStatus : BehaviorSubject<any> = new BehaviorSubject('Worked');
  socketUrl = environment.socketUrl;

  constructor( private commonService: CommonService) {
    this.socket = socketIo.connect(this.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity
    })
    if(this.commonService.getFromLocalStorage("cure_admin_token")){
      this.initSocket();
    }
  }

  initSocket() {
    let token = null;
    if(this.commonService.getFromLocalStorage("cure_admin_token")){
      token = this.commonService.getFromLocalStorage("cure_admin_token");
      this.socket = socketIo.connect(this.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        transports: ['polling'],
        query: {
          token: token
        }
      });
    }
  
    this.socket.on('connect', (res: any) => {
      this.socket.on( message => {
      
        this.notificationObserve.next(message)
      });
    });
    this.socket.on('disconnect', (message: any) => {
      console.log('debug disconnect', message);
    });
  }

  getNotificationStatus(key){
    return new Observable<any>((observer) => {
      this.socket.on(key, (data) => observer.next(data));
    });
  }


  disconnectSocket() {
    if(this.socket.connected){
      this.socket.disconnect();
    }
  }

}
