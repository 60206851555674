import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from "jquery";
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { NotificationSocketService } from '../../services/socket/notification-socket.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  showDropBox: boolean = false;
  showDropBox1: boolean = false;
  show_side: boolean = false;
  constructor(
    private router:Router,
    private api : HttpsService,
    private toastr: ToastrService,
    private NotificationSocketService: NotificationSocketService,
    private common : CommonService
    ) {
      this.getNotificationStatus();
     }

  ngOnInit(): void {
      $(document).on("click", '#menu-toggle', function(event){
        event.preventDefault();
        $("#wrapper").toggleClass("toggled");
        $("body").toggleClass("body");
      });
      $(window).resize(function(e) {
        if($(window).width()<=768){
          $("#wrapper").removeClass("toggled");
        }else{
          $("#wrapper").addClass("toggled");
        }
      });
  }

  onLogout(){
    this.api.getRequest("logout",'').subscribe((res : any)=>{
      if(res.statusCode == 200){
        localStorage.clear();
        this.NotificationSocketService.disconnectSocket();
        this.router.navigate(['/auth/login']);
      }else{
        this.common.errorMessage(res.message);
      }
    })
  }

  renderZendesk(){
    window.open('https://curegroupgmbhsupport.zendesk.com/','_blank');
  }

  getNotificationStatus() {
    this.NotificationSocketService.getNotificationStatus('newBooking').subscribe(res => {
      if (res) {
        this.playAudio();
        this.toastr.success('New Order').onTap.subscribe(
          (func) => {
            this.router.navigateByUrl('/pages/orders', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/pages/orders'], { queryParams: {changeStatus: true}});
          });
          });
          if(this.router.url == '/pages/orders'){
            this.router.navigate(['/pages/orders'], { queryParams: {changeStatus: true}});
          }
      }
    });
  }

  playAudio() {    
    console.log("play audio")
    const audio = new Audio();
    audio.src = 'assets/sounds/notification-sound1.mp3';
    audio.load();
    audio.play();
  }
}


