import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { GetInterceptorService } from './services/interceptor/get-interceptor.service';
import { SetInterceptorService } from './services/interceptor/set-interceptor.service'; 
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';
import { ConfirmationStatusComponent } from './shared/confirmation-status/confirmation-status.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ConfirmationStatusComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    NgxIntlTelInputModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    PaginationModule.forRoot(),
    ToastrModule.forRoot({
      "positionClass": "toast-bottom-full-width"
    })
  ],
  providers: [   {
    provide: HTTP_INTERCEPTORS,
    useClass: GetInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SetInterceptorService,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
