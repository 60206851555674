import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpsService } from '../../services/http/http.service';
import { CommonService } from  '../../services/common/common.service';
import { MustMatch } from 'src/app/services/validator/must-match.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  isSubmitted: boolean = false;
  resetPasswordId: any;

  constructor(
    private router: Router,
    private https: HttpsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, 
    private commonService: CommonService
  ) {
    activatedRoute.queryParams.subscribe((params) =>{ 
      this.resetPasswordId= params['id'];
     })
  }

  ngOnInit() {
    
    this.form = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
    }, {
        validators: MustMatch('newPassword', 'confirmPassword'),
      })
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {   
    this.isSubmitted = true; 
    if (this.form.valid) {
      const data={
        newPassword:this.form.controls['newPassword'].value,
        id:this.resetPasswordId
      }
      this.https.postRequest('resetPassword', data).subscribe((res: any) => {
        if (res['statusCode']==200) {
          this.router.navigate(['/auth/login'])
          this.commonService.successMessage(res['message']);
        }else{
          this.commonService.errorMessage(res['message'])
        }
      });
    }
   }
}
