import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService} from '../../services/common/common.service';

@Injectable({
  providedIn: 'root',
})
export class GetInterceptorService {
  constructor(private router: Router, 
    private spinner : NgxSpinnerService,
    private common: CommonService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        
        if (event instanceof HttpResponse) {
          this.spinner.hide();
        }
      }, (error: any) => {
        this.spinner.hide();
        if (error['status'] === 401) {
          this.common.errorMessage(error['error']['message']);
          localStorage.clear();
          this.router.navigate(['/']);
        }else if (error['status'] === 400) {
          this.common.errorMessage(error['error']['message']);
        }else if (error['status'] === 408) {
          this.router.navigate(['/']);
          localStorage.clear();
          this.common.errorMessage(error['error']['message']);
        }else if (error['status'] === 500) {
          this.common.errorMessage(error['error']['message']);
        }else{
          this.common.errorMessage("Something went wrong... Try Checking your network connections!")
        }
      }));
    } 
}

