// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'https://api.getcure.app/api/v1/admin/',
  // imageUrl:'https://api.getcure.app/api/v1/',
  // socketUrl: 'https://api.getcure.app:5000'
  // baseUrl:'https://betaapi.getcure.app/api/v1/admin/',
  // imageUrl:'https://betaapi.getcure.app/api/v1/',
  // socketUrl: 'https://betaapi.getcure.app:5500'

  // baseUrl:'https://dev.webdevelopmentsolution.net:5009/api/v1/admin/',
  // imageUrl:'https://dev.webdevelopmentsolution.net:5009/api/v1/'

  // https://demo.appdukaan.com:4400
  baseUrl:'https://demo.appdukaan.com:4400/api/v1/admin/',
  imageUrl:'https://demo.appdukaan.com:4400/api/v1/',
  socketUrl: 'https://demo.appdukaan.com:4400'
};

// https://dev.webdevelopmentsolution.net:5009/  // appgrowth server url

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
