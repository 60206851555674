import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorService } from '../../services/validator/validator.service';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {

  constructor() { }
   
  ngOnInit(){
    // console.log("validator working");    
  }

  @Input() control: FormControl;
  @Input() labelName?: string;
  @Input() isSubmitted?: boolean;

  get errorMessage(): boolean {    
    for (const propertyName in this.control?.errors) {      
      if (
        this.control.errors.hasOwnProperty(propertyName)
      ) {
        return ValidatorService.getValidationErrorMessage(
          propertyName,
          this.control.errors[propertyName],
          this.labelName
        );
      }
    }

    return undefined;
  }

}
